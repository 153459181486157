import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import gamesReducer from "./reducer/games";
import NavBar from "./components/Navbar";
import Capitals from "./components/Capitals";
import Home from "./components/Home";

function App() {
  const store = configureStore({
    reducer: {
      games: gamesReducer,
    },
  });

  return (
    <>
      <title>Geography Dash</title>
      <Provider store={store}>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/capitals" element={<Capitals />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
