import { Button, Modal } from "react-bootstrap";

export default function InstructionModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You have 60 seconds to guess as many capitals as you can</p>
        <p>Accents can be omitted</p>
        <p>
          Easy mode: you have to select the correct answer amongst 4 options
        </p>
        <p>
          Hard mode: you have to type in the correct answer. Hit 'Enter' to skip
          to the next country
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
