const initialState = {
    capitals: {
        highScore: 0
    }
}

export default function gamesReducer(state = initialState, action) {
    switch (action.type) {

        case 'SET_CAPITALS_GAME_HIGH_SCORE':
            return {
                ...state,
                capitals: {
                    ...state.capitals,
                    highScore: action.payload
                }
            }

        default:
            return state
    }
}
