import { useEffect, useState } from "react";

export default function Timer({
  gameInProgress,
  setGameInProgress,
  handleGameOver,
}) {
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    if (!gameInProgress) return;

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(interval);
          setGameInProgress(false);
          handleGameOver();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timer]);

  return <h5>Time left: {timer}</h5>;
}
